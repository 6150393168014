import React, { Component } from 'react';
import Terminal from 'terminal-in-react';

class App extends Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh"
        }}
      >
        <Terminal
          color='green'
          backgroundColor='black'
          barColor='black'
          style={{ fontWeight: "bold", fontSize: "1em" }}
          commands={{
            'linkedin': () => window.open('https://www.linkedin.com/in/alejandromerello/', '_blank'),
            'xing': () => window.open('https://www.xing.com/profile/Alejandro_Merello', '_blank'),
          }}
          descriptions={{
            'linkedin': 'Visit me on LinkedIn',
            'xing': 'Visit me on XING'
          }}
          msg='Thank you for your visit. To see available options type help'
          startState='maximised'
        />
      </div>
    );
  }
}

export default App;
